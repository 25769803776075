import React, { useEffect } from "react";
import SidebarComponent from "./SidebarComponent";
import ChatUsersComponent from "./ChatUsersComponent";
import ChatListInputComponent from "./ChatListInputcomponent";
import { useLocation } from 'react-router-dom';
import { useDispatch ,useSelector} from 'react-redux'
import { setToken } from "../redux/reducer";
const ChatScreen = ({ props, navigate }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { token, usersList } = useSelector((state) => state?.sliceReducer)
    useEffect(() => {
        const savedData = localStorage.getItem('myData');
        if (savedData != null) {
            const data = JSON.parse(savedData)
            dispatch(setToken(data?.token))
        }
    }, [])
    return (
        <div>
            <div className="header-container-chat">
                <div
                  className='first_division'>
                    <SidebarComponent />
                </div>
                <div
                className='second_division'>
                    <ChatUsersComponent location={location} />
                </div>
                <div
                className='third_division'>
                  {usersList.length > 0 &&  <ChatListInputComponent location={location} /> } 
                </div>
            </div>
        </div>
    );
};

export default ChatScreen;
