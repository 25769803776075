import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatUser:{},
  token:'',
  usersList:[],
  messages:[]
 
}

const sliceReducer = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    
    
    setInitialChatUser(state, action) {
      state.chatUser = action.payload
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setUserList(state, action) {
      state.usersList = action.payload
    },
    setMessages(state, action) {
      state.messages = action.payload
    },

  }
})

export const {
  setInitialChatUser,
  setToken,
  setUserList,
  setMessages
} = sliceReducer.actions;
export default sliceReducer.reducer;
