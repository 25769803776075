import React from "react"
import SidebarComponent from "./SidebarComponent"
import DepositeList from "./DepositeList"


const DepositeScreen = () => {

    return (
        <div>
            <div className="header-container-chat">
                <div
                    className='first_division'>
                    <SidebarComponent />
                </div>
                <div className='deposite_division'>
                    <DepositeList />
                </div>


            </div>
        </div>
    )
}

export default DepositeScreen