import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loanRenderImages } from "../constants/helper";

const ClaimLodgeDetailsModals = ({ close, showDetailsModal, selectedUser }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [myUrl, setUrl] = useState("");

  const onImageClick = (url) => {
    setModal(true);
    setUrl(url);
  };

  return (
    <>
      {console.log(selectedUser)}
      {selectedUser && (
        <div className="detailmain">
          <div className="headerDetail">
            <h2>Financial Loan Detail</h2>
            <Button
              variant="secondary"
              onClick={close}
              style={{
                backgroundColor: "#FF914D",
                border: "#FF914D",
                height: 40,
                margin: 10,
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: 10,
            }}
          >
            <div>
            <p>
            <strong>Name :</strong> {selectedUser?.userId?.name}
          </p>
          <p>
            <strong>Mobile Number:</strong> {selectedUser?.userId?.phoneNumber}
          </p>
          <p><strong>Claim Name :</strong> {selectedUser?.claimName}</p>
          <p><strong>Claim Description :</strong> {selectedUser?.claimDescription}</p>
          <p><strong>Claim Date :</strong> {selectedUser?.claimDate.slice(0, 10)}</p>
          <p><strong>Claiming :</strong> {selectedUser?.claiming}</p>
          <p><strong>Culprit Name :</strong> {selectedUser?.culpritName}</p>
          <p><strong>Culprit Driving Licence :</strong> {selectedUser?.culpritDrivingLicence}</p>
          <p><strong>List of Damages: :</strong>{selectedUser?.listDamages}</p>
            </div>

          </div>

          <div
            style={{
              marginLeft: 190,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ display: "flex", flexDirection: "column" }}>
              <strong>Evidence Image :</strong>
              <br />
              <>{loanRenderImages(selectedUser?.evidencePhotoUrl, onImageClick)}</>
            </p>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <p style={{ display: "flex", flexDirection: "column" }}>
              <strong>Police Evidence Image :</strong>
              <br />
              <>{loanRenderImages(selectedUser?.policeEvidencePhoto, onImageClick)}</>
            </p>
          </div>
        </div>
      )}

      <Modal show={modal}>
        <Modal.Header
          closeButton
          onHide={() => {
            setModal(false);
          }}
        >
          {/* <Modal.Title>Create Account</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              height: 570,
              width: "100%",
              alignSelf: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              style={{ height: 540, width: "100%", alignSelf: "center" }}
              src={myUrl.trim()}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ClaimLodgeDetailsModals;
