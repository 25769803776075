import React, { useEffect, useState } from "react";
import SidebarComponent from "./SidebarComponent";
import axios from "axios";
import { userLogo } from "../constants/constantMessages";
import { FaInfo, FaBan,FaSearch, FaMailBulk } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { Table } from "react-bootstrap";
import Tabs from "../Modals/Tabs";
import BlockedUserTab from "../Modals/BlockedUserTab";
import { BlockedUserHandler } from "../constants/helper";
import DetailModal from "../Modals/DetailModal";

const BlockUserComponent = () => {
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const { blocked, getBlockedUserList, blockUser, UnblockUser } = BlockedUserHandler()
  

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "http://16.16.47.146:3213/api/get-block-user"
      );
      setUserData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getBlockedUserList(0)
  }, []);

  const handleShowDetails = (user) => {
    setSelectedUser(user);
    setShowDetailsModal(true);
  };

  const handleOpenConfirmModal = (user) => {
    setSelectedUser(user);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
    window.location.reload();
  };
  const renderImages = (imageUrls) => {
    return imageUrls?.split(",")?.map((url, index) => (
      <img className="userImages" key={index} src={url?.trim()} alt={`Image ${index}`} />
    ));
  };
 
  const handleUnblockUser = async () => {
    try {
      const response = await axios.post(
        `http://16.16.47.146:3213/api/unblock-user?id=${selectedUser?._id}`,
        { blockStatus: false }
      );
      if (response.data.success) {
        setShowFeedbackModal(true);
        // Refetch data after successful unblocking
        // fetchData();
        getBlockedUserList(0)
      } else {
        console.error("Error unblocking user:", response.data.message);
      }
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  const handleUnblockAdminUser = async () => {
    try {
      const response = await axios.post(
        `http://16.16.47.146:3213/api/admin/un-block-admin?id=${selectedUser?._id}`,
        { blockStatus: false }
      );
      if (response.data.success) {
        setShowFeedbackModal(true);
        // Refetch data after successful unblocking
        // fetchData();
        getBlockedUserList(activeTab)
      } else {
        console.error("Error unblocking user:", response.data.message);
      }
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  const handleTabClick=(index)=>{
    getBlockedUserList(index)
    setActiveTab(index)
  }

  return (
    <div>
      {showDetailsModal &&
        <DetailModal showDetailsModal={showDetailsModal}
          close={() => { setShowDetailsModal(false) }}
          selectedUser={selectedUser} />
     }
      <div className="header-container">
        <SidebarComponent />
        <div className="user-container">
          <div className="search-container">
            {/* <h1>Blocked User</h1> */}
            <BlockedUserTab handleTabClick={(index) => {handleTabClick(index)  }} activeTab={activeTab} />
            <div className="user-search-container">
              <input
                type="text"
                className="inputTextUserSearchField"
                placeholder="Search User"
                name="search"
              />
              <Button variant="primary" style={{ backgroundColor: '#FF914D', borderWidth: 0 }}>
                <FaSearch />
              </Button>
            </div>
          </div>
          <div className="user-table-data">
            <Table
              striped
              bordered
              hover
              size="sm"
              style={{ borderRadius: "5px" }}>
              <thead>
                <tr>
                  <th>S.NO.</th>
                  <th>Name</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>Gender</th>
                  <th>View Details</th>
                  <th>Unblock</th>
                </tr>
              </thead>
              <tbody>
                {blocked?.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user?.name}</td>
                    <td>{user?.phoneNumber ? user?.phoneNumber : user?.mobileNumber}</td>
                    <td>{user?.email}</td>
                    <td>
                      {user?.gender && (
                        <Button
                          variant={
                            user.gender.toLowerCase() === "male"
                              ? "outline-warning"
                              : "outline-info"
                          }
                        >
                          {user.gender}
                        </Button>
                      )}
                    </td>
                    <td>
                      <Button
                      style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                        variant="primary"
                        onClick={() => handleShowDetails(user)}
                      >
                        <FaInfo />
                      </Button>
                    </td>
                    <td>
                      <Button
                      style={{ backgroundColor: '#FF914D', borderWidth: 0 }}
                        variant="primary"
                        onClick={() => handleOpenConfirmModal(user)}
                      >
                        Unblock
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          
        </div>
      </div>
      
     

      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to unblock this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>{ activeTab === 0 ?  handleUnblockUser() : handleUnblockAdminUser()}}>
            Unblock
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showFeedbackModal} onHide={handleCloseFeedbackModal}>
        <Modal.Body>User unblocked successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFeedbackModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      
    </div>
  );
};

export default BlockUserComponent;
