import React from "react"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { setMessages, setUserList } from "../redux/reducer";
const ChatHook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { chatUser,token,messages } = useSelector((state) => state?.sliceReducer)


    const getRecentChatUsers = async (mytoken) => {
        try {
            const token = mytoken;
            const headers = {
                Authorization: token,
            };
            const response = await axios.get(
                "https://api.yoonetinsurance.com/api/message/get-users",
                { headers }
            );

            dispatch(setUserList(response?.data?.data));
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };


    const getChatMeassages = async (mytoken, id) => {
        
        try {
            const token = mytoken;
            const headers = {
                Authorization: token,
            };
            const response = await axios.get(
                `https://api.yoonetinsurance.com/api/message/get-message?id=${id}`,
                { headers }
            );

            dispatch(setMessages(response?.data?.data));
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }

    const senChatMessage = async (param) => {
      
        try {
            const token = param?.mytoken;
            const headers = {
                Authorization: token,
                 'Content-Type': 'application/json' 
            };
        
            const postData = {
                message: param?.message,
                reciverId: param?.reciverId,
                type:"text"
            };
        
            const response = await axios.post(
                'https://api.yoonetinsurance.com/api/message',
                postData,
                {headers} 
            );
        
            
            getChatMeassages(token,chatUser?.message?.threadId)
        } catch (error) {
           
        }
       
       
    
    }

    const senImageMessage = async (param) => {
        try {
            const postData = {
                message: param?.image,
                reciverId: param?.reciverId,
                type:"image"
            };
            const token = param?.mytoken;
            const headers = {
                Authorization: token,
                'Content-Type': 'multipart/form-data'
            };
        
            const response = await axios.post(
                'https://api.yoonetinsurance.com/api/message',
                postData,
                {headers} 
            );
           
            getChatMeassages(token,chatUser?.message?.threadId)
        } catch (error) {
           
        }
       
       
    
    }






    return { getRecentChatUsers,getChatMeassages,senChatMessage,senImageMessage }


}

export default ChatHook