import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loanRenderImages } from "../constants/helper";

const FinancialDetailModal = ({ close, showDetailsModal, selectedUser }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [myUrl, setUrl] = useState("");

  const onImageClick = (url) => {
    setModal(true);
    setUrl(url);
  };

  return (
    <>
      {console.log(selectedUser)}
      {selectedUser && (
        <div className="detailmain">
          <div className="headerDetail">
            <h2>Financial Loan Detail</h2>
            <Button
              variant="secondary"
              onClick={close}
              style={{
                backgroundColor: "#FF914D",
                border: "#FF914D",
                height: 40,
                margin: 10,
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-start",
              paddingRight: 10,
            }}
          >
            <div>
              <p>
                <strong>Loan Terms:</strong>
                {selectedUser?.loanTerms}
              </p>

              <p>
                <strong>Loan Amount:</strong>
                {selectedUser?.loanAmount}
              </p>
              <p>
                <strong>Reason For Loan:</strong>
                {selectedUser?.reasonForLoan}
              </p>
              <p>
                <strong>Bank Account Number:</strong>{" "}
                {selectedUser?.accountNumber}
              </p>
              <p>
                <strong>Bank Name:</strong> {selectedUser?.bankName}
              </p>
              <p>
                <strong>Name:</strong> {selectedUser?.userId?.name}
              </p>
              <p>
                <strong>Upload Id:</strong> {selectedUser?.uploadid}
              </p>
              <p>
                <strong>Colletral:</strong> {selectedUser?.collateral}
              </p>
              <p>
                <strong>Collateral Value Approved By:</strong>{" "}
                {selectedUser?.collateralValueApprovedBy}
              </p>
            </div>

            <div style={{
              marginLeft:"10%"
            }}>
              <p>
                <strong>Reason For Loan:</strong> {selectedUser?.reasonForLoan}
              </p>
              <p>
                <strong>Tin Number:</strong> {selectedUser?.tinNumber}
              </p>
              <p>
                <strong>Company Name:</strong> {selectedUser?.companyName}
              </p>
              <p>
                <strong>Address:</strong> {selectedUser?.address}
              </p>
              <p>
                <strong>Employement Address:</strong>{" "}
                {selectedUser?.employementAddress}
              </p>
              <p>
                <strong>Policy Number:</strong> {selectedUser?.policyNumber}
              </p>
            </div>
          </div>

          <div
            style={{
              marginLeft: 190,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ display: "flex", flexDirection: "column" }}>
              <strong>Tin Registration Image</strong>
              <br />
              <>{loanRenderImages(selectedUser?.tinRegistrationImage, onImageClick)}</>
            </p>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <p style={{ display: "flex", flexDirection: "column" }}>
              <strong>Business Licence Image</strong>
              <br />
              <>{loanRenderImages(selectedUser?.businessLicenseImage, onImageClick)}</>
            </p>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <p style={{ display: "flex", flexDirection: "column" }}>
              <strong>Id Image(Front)</strong>
              <br />
              <>{loanRenderImages(selectedUser?.uplaodIdImage, onImageClick)}</>
            </p>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <p style={{ display: "flex", flexDirection: "column" }}>
              <strong>Id Image Back</strong>
              <br />
              <>
                {loanRenderImages(
                  selectedUser?.idNumber,
                  onImageClick
                )}
              </>
            </p>
          </div>
        </div>
      )}

      <Modal show={modal}>
        <Modal.Header
          closeButton
          onHide={() => {
            setModal(false);
          }}
        >
          {/* <Modal.Title>Create Account</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              height: 570,
              width: "100%",
              alignSelf: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              style={{ height: 540, width: "100%", alignSelf: "center" }}
              src={myUrl.trim()}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FinancialDetailModal;
