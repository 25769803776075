import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import TextInputModal from "../Modals/TextInputModal";
import { useFormik } from "formik";
import * as Yup from "yup";
const AddDeposits = ({ close }) => {
  const [userData, setUserData] = useState([]);
  const { token } = useSelector((state) => state?.sliceReducer);
  const [search, setSearch] = useState("");
  const [list, setList] = useState(true);
  const [selected, setSected] = useState(null);
  const fetchData = async () => {
    const headers = {
      Authorization: token,
    };
    try {
      const response = await axios.get(
        `http:/16.16.47.146:3213/api/user/get-approved-users`,
        { headers }
      );

      setUserData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setInputValue = (e) => {
    const query = e.toLowerCase();
    setSearch(e);
    if (e.length > 0) {
      const data = userData.filter((item) =>
        item?.name?.toLowerCase()?.includes(query)
      );
      setUserData(data);
    } else {
      fetchData();
    }
  };

  const selectvalue = () => {
    if (selected != null) {
      setList(false);
    } else alert("Please select user.");
  };

  const handleRadioChange = (item) => {
    setSected(item);
  };

  const formik = useFormik({
    initialValues: {
      emails: selected?.email,
      name: selected?.name,
      userId: selected?._id,
      transactionId: "",
      amount: "",
      policyNumber:""
    },
    validationSchema: Yup.object({
      transactionId: Yup.string().required("Required"),
      amount: Yup.string().required("Required"),
      policyNumber:Yup.string().required("Required")
    }),
    onSubmit: async (values) => {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          `http:/16.16.47.146:3213/api/insurance/create-deposits`,
          {
            userId: selected?._id,
            amount: values.amount,
            transactionId: values.transactionId,
            policyNumber:values.policyNumber
          },
          { headers }
        );
        close();
        setTimeout(() => {
          alert("Deposit created sucessfully.");
        });
      } catch (error) {
        alert(error.response.data.message);
      }
    },
  });

  return (
    <Modal show={true}>
      <Modal.Header
        closeButton
        onHide={() => {
          close();
        }}
      >
        <Modal.Title>Add Deposits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {list ? (
          <div
            style={{
              height: 570,
              width: "100%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextInputModal
              setInputValue={(e) => {
                setInputValue(e);
              }}
              inputValue={search}
            />
            <div className="user-table-data">
              <Table
                striped
                bordered
                hover
                size="sm"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {userData.map((user, index) => (
                  <tr
                    key={index}
                    style={{
                      width: 500,
                      justifyContent: "space-between",
                      height: 60,
                      alignItems: "center",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>
                      {" "}
                      <img
                        src={user?.image}
                        style={{
                          height: 40,
                          width: 40,
                          borderRadius: 20,
                          alignSelf: "center",
                        }}
                      />
                    </td>
                    <td>{user?.name}</td>
                    <td>{user?.phoneNumber}</td>

                    <td>{user?.transactionId}</td>
                    <td>
                      <input
                        type="radio"
                        value={user._id}
                        checked={selected?._id === user._id}
                        onChange={() => handleRadioChange(user)}
                      />
                    </td>
                  </tr>
                ))}
              </Table>
            </div>

            <button
              className="loginButtonModal"
              onClick={() => {
                selectvalue();
              }}
              style={{ position: "absolute", bottom: 30 }}
            >
              Select
            </button>
          </div>
        ) : (
          <div
            style={{
              height: 570,
              width: "100%",
              alignSelf: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                width: "90%",
                alignSelf: "center",
              }}
              onSubmit={formik.handleSubmit}
            >
              <div>
               
                <input
                  className="inputTextFieldModal"
                  type="hidden"
                  id="id"
                  name="_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={selected?._id}
                  contentEditable={false}
                />
              </div>
              <div>
                <label className="inputTextModal" htmlFor="Name">
                  Name
                </label>
                <input
                  className="inputTextFieldModal"
                  type="text"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={selected?.name}
                  contentEditable={false}
                />
              </div>
              <div>
                <label className="inputTextModal" htmlFor="email">
                  Email
                </label>
                <input
                  className="inputTextFieldModal"
                  type="text"
                  id="emails"
                  name="emails"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={selected?.email}
                  contentEditable={false}
                />
              </div>

              <div>
                <label className="inputTextModal" htmlFor="password">
                  Phone
                </label>
                <input
                  className="inputTextFieldModal"
                  type="text"
                  id="number"
                  name="number"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={selected?.phoneNumber}
                  contentEditable={false}
                />
              </div>

              <div>
                <label className="inputTextModal" htmlFor="policyNumber">
                  Policy Number
                </label>
                <input
                  className="inputTextFieldModal"
                  type="text"
                  id="policyNumber"
                  name="policyNumber"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.policyNumber}
                />
                {formik.touched.policyNumber && formik.errors.policyNumber ? (
                  <div>{formik.errors.policyNumber}</div>
                ) : null}
              </div>

              <div>
                <label className="inputTextModal" htmlFor="password">
                  Amount
                </label>
                <input
                  className="inputTextFieldModal"
                  type="text"
                  id="amount"
                  name="amount"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.amount}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div>{formik.errors.amount}</div>
                ) : null}
              </div>

              <div>
                <label className="inputTextModal">Transaction Id</label>
                <input
                  className="inputTextFieldModal"
                  type="text"
                  id="transactionId"
                  name="transactionId"
                  placeholder=""
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.transactionId}
                />
                {formik.touched.transactionId && formik.errors.transactionId ? (
                  <div>{formik.errors.transactionId}</div>
                ) : null}
              </div>

              <button className="loginButtonModal" type="submit">
                Submit
              </button>
            </form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddDeposits;
