import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { LOGO } from "../constants/constantMessages";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import UseSession from "../constants/useSession";

const LoginComponent = () => {
  const [popupMessage, setPopupMessage] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseFailModal = () => setShowFailModal(false);
  const {mySession, saveSession, getSession } = UseSession()

  useEffect(() => {

    const getSession = () => {
      const savedData = localStorage.getItem('myData');
      if (savedData!= null) {
          const data = JSON.parse(savedData)        
           navigate("/dashboard")
      }

  };
    getSession()
   
  }, [])


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
        try {
          const deviceToken = localStorage.getItem("deviceToken");
          const response = await axios.post(
            `https://api.yoonetinsurance.com/api/admin/login`,
            { email: values?.email, password: values?.password,deviceToken }
          );
          saveSession(response?.data?.data)
          setPopupMessage(response.data.popupMessage);
          console.log("============>>>token",response.data.data)
          setShowSuccessModal(true);
          navigate("/dashboard");
        } catch (error) {
          setPopupMessage(error.response.data.message);
          setShowFailModal(true);
        }

    },
  });

  return (
    <div className="landingPage">
      <div className="sidebar">
        <div className="logo">

          <img src={LOGO} alt="Logo" />

        </div>
        <h3 className="login">Log in</h3>
        {/* <h6 className="user-type-heading">Select User Type</h6> */}
        {/* <div className="user-type-radio">
          <div style={{ display: 'flex', justifyContent: "space-between", paddingInlineEnd: 50, marginTop: 60, paddingInlineStart: 3 }}>
            <label>
              <input
                type="radio"
                name="userType"
                value="horn"
                checked={formik.values.userType === "horn"}
                onChange={formik.handleChange}
              />
              Horn
            </label>
            <label>
              <input
                type="radio"
                name="userType"
                value="banks"
                checked={formik.values.userType === "banks"}
                onChange={formik.handleChange}
              />
              Banks
            </label>
          </div>
          <div style={{ display: 'flex', justifyContent: "space-between", paddingInlineEnd: 2, paddingInlineStart: 3 }}>
            <label>
              <input
                type="radio"
                name="userType"
                value="insurance"
                checked={formik.values.userType === "insurance"}
                onChange={formik.handleChange}
              />
              Insurance
            </label>
            <label>
              <input
                type="radio"
                name="userType"
                value="insurance"
                checked={formik.values.userType === "insurance"}
                onChange={formik.handleChange}
              />
              Super Admin
            </label>
          </div>
        </div> */}
        <form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '40%', paddingTop: 30,alignItems:'center' ,width:'100%'}} onSubmit={formik.handleSubmit}>
          <div style={{width:'100%',padding:'15px'}}>
            <label className="inputText" htmlFor="email">Email Address</label>


            <input
              className="inputTextField"
              type="text"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>

          <div style={{width:'100%',padding:'15px'}}>
            <label className="inputText" htmlFor="password">
              Password
            </label>
            <input
              className="inputTextField"
              type="password"
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </div>

          {/* <div className="submitButton"> */}
            <button className="loginButton" type="submit">
              Log in
            </button>
          {/* </div> */}
        </form>
      </div>
      <div className="content-section">
        <img
          alt="backgorundImage"
          src="https://paygoc1.s3.eu-north-1.amazonaws.com/Illustration.png"
        />
      </div>
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have successfully logged in!
          </p>
          <p>{popupMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={showUserTypeModal} onHide={handleCloseUserTypeFailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Please Select User Type</strong>
          </p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserTypeFailModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={showFailModal} onHide={handleCloseFailModal}>
        <Modal.Header closeButton>
          <Modal.Title>Login Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{popupMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFailModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoginComponent;
