import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";

const AddMember = ({ close }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [img, setImg] = useState(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        handleImageUpload(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const formik = useFormik({
    initialValues: {
      emails: "",
      passwords: "",
      name: "",
      userType: "",
      number: "",
    },
    validationSchema: Yup.object({
      emails: Yup.string().required("Required"),
      passwords: Yup.string().required("Required"),
      userType: Yup.string().required("Required"),
      name: Yup.string().required("Required"),
      number: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      if (img == null) {
        alert("Please upload image.");
      } else {
        try {
          const response = await axios.post(
            `https://api.yoonetinsurance.com/api/admin/create-admin`,
            {
              email: values?.emails,
              password: values?.passwords,
              name: values.name,
              userType: values.userType,
              mobileNumber: values.number.toString(),
              image: img,
            }
          );
          close();
          setTimeout(() => {
            alert("User created sucessfully.");
          });
        } catch (error) {
          alert("This User Already exist");
        }
      }
    },
  });

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (Image) => {
    if (!Image) {
      return;
    }

    const byteCharacters = atob(Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });
    const formData = new FormData();
    formData.append("image", blob, "image.png");
    const apiUrl = "https://api.yoonetinsurance.com/api/upload-image";

    formData.append("image", Image);
    axios
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setImg(response.data?.filename);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        // Handle error
      });
  };

  return (
    <Modal show={true}>
      <Modal.Header
        closeButton
        onHide={() => {
          close();
        }}
      >
        <Modal.Title>Create Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            height: 570,
            width: "100%",
            alignSelf: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              width: "90%",
              alignSelf: "center",
            }}
            onSubmit={formik.handleSubmit}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{ height: 70, width: 70, borderRadius: 35 }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "20%",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
              <button
                style={{
                  borderRadius: 5,
                  backgroundColor: "#FF914D",
                  borderColor: "#FF914D",
                  color: "white",
                  marginTop: 10,
                }}
                onClick={() => handleButtonClick()}
              >
                Upload Image
              </button>
            </div>
            <div>
              <label className="inputTextModal" htmlFor="Name">
                Name
              </label>
              <input
                className="inputTextFieldModal"
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div>{formik.errors.name}</div>
              ) : null}
            </div>
            <div>
              <label className="inputTextModal" htmlFor="email">
                Email
              </label>
              <input
                className="inputTextFieldModal"
                type="text"
                id="emails"
                name="emails"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emails}
              />
              {formik.touched.emails && formik.errors.emails ? (
                <div>{formik.errors.emails}</div>
              ) : null}
            </div>
            <div>
              <label className="inputTextModal" htmlFor="email">
                User Type
              </label>
              <select
                className="inputTextFieldModal"
                id="userType"
                name="userType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userType}
              >
                <option value="">Select...</option>
                <option value="banks">Bank</option>
                <option value="Admin"> Admin</option>
                <option value="insurance">Insurance</option>
              </select>

              {formik.touched.userType && formik.errors.userType ? (
                <div>{formik.errors.userType}</div>
              ) : null}
            </div>

            <div>
              <label className="inputTextModal" htmlFor="password">
                Phone
              </label>
              <input
                className="inputTextFieldModal"
                type="text"
                id="number"
                name="number"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.number}
              />
              {formik.touched.number && formik.errors.number ? (
                <div>{formik.errors.number}</div>
              ) : null}
            </div>

            <div>
              <label className="inputTextModal" htmlFor="password">
                Password
              </label>
              <input
                className="inputTextFieldModal"
                type="password"
                id="passwords"
                name="passwords"
                placeholder=""
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwords}
              />
              {formik.touched.passwords && formik.errors.passwords ? (
                <div>{formik.errors.passwords}</div>
              ) : null}
            </div>

            <button className="loginButtonModal" type="submit">
              Submit
            </button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddMember;
