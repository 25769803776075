import React, { useEffect } from 'react';
import UseSession from '../constants/useSession';
import { FirstLeeterToUpperCase } from '../constants/helper';



const TextInputModal = ({setInputValue,inputValue}) => {
   
   
    return (
      
        <div style={{alignItems:'center',justifyContent:'center'}}>
          
          <input
            id="inputField"
            placeholder='Search'
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{display:'flex',flexDirection:'row',
            alignItems:'center',borderWidth:1,borderColor:'grey',
            borderRadius:5,height:37,width:'96%',boxShadow: 'none',margin:10,alignSelf:'center',paddingLeft:10,
            }}

            
          />
        </div>
        
    
        
    );
}

export default TextInputModal;