import React, { useState } from "react"
import { BASEURL } from "./constantMessages";
import axios from "axios";
const HandleModal = () => {
  const [open, setOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  const [tabData, setTabData] = useState([])
  const [hasMorePages, setHasMorePages] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const setValues = () => {
    setOpen(!open)
  }

  const handleTabClick = (index) => {
    setActiveTab(index);
    fetchData(index)
  };

  const fetchData = async (index) => {
    if (index === 0) {
      fetchDatas()
    } else {
      const endPoint = index === 1 ? "Admin" : index === 2 ? "insurance" : index === 3 ? "banks" : null
      try {
        const response = await axios.get(
          `${BASEURL}get-user-types?types=${endPoint}`
        );
        setTabData(response?.data?.data);

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

  };


  const fetchDatas = async () => {
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/user/get-approved-user?page=${1}&limit=10`
      );
      setTabData(response?.data?.data);
      setHasMorePages(response?.data?.data?.length === 10);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchNextPageData = async () => {
    setCurrentPage(currentPage +1)
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/user/get-approved-user?page=${currentPage +1}&limit=10`
      );
      setTabData(response?.data?.data);
      setHasMorePages(response?.data?.data?.length === 10);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchpreviousPageData = async () => {
    setCurrentPage(currentPage -1)
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/user/get-approved-user?page=${currentPage -1}&limit=10`
      );
      setTabData(response?.data?.data);
      setHasMorePages(response?.data?.data?.length === 10);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  



  return { open, setValues, activeTab, handleTabClick, tabData, fetchNextPageData,hasMorePages ,currentPage,fetchpreviousPageData}
}

export default HandleModal


export const FirstLeeterToUpperCase = (string) => {
  const capitalizedString = string?.charAt(0)?.toUpperCase() + string?.slice(1)
  return capitalizedString
}



export const BlockedUserHandler = () => {
  const [blocked, setBlocked] = useState([])

  const fetApi = async (type) => {
    try {
      const response = await axios.get(
        `https://api.yoonetinsurance.com/api/admin/get-list-of-blocked-admin?types=${type}`
      );
      setBlocked(response?.data?.data)
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.yoonetinsurance.com/api/get-block-user"
      );
      setBlocked(response?.data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getBlockedUserList = (index) => {

    const type = index === 1 ? "Admin" : index === 2 ? "insurance" : index === 3 ? "banks" : null
    if (index == 0) {
      fetchData()
    } else {
      fetApi(type)
    }

  }




  const blockUser = () => {

  }

  const UnblockUser = () => {

  }


  return { blocked, getBlockedUserList, blockUser, UnblockUser }
}


export const renderImages = (imageUrls,onImageClick) => {
  return imageUrls?.split(",")?.map((url, index) => (
    <img onClick={()=>onImageClick(url)} className="userImagesModal" key={index} src={url.trim()} alt={`Image ${index}`} />
  ));
};
export const loanRenderImages = (imageUrls,onImageClick) => {
  return imageUrls?.split(",")?.map((url, index) => (
    <img onClick={()=>onImageClick(url)} className="loanImagesModal" key={index} src={url.trim()} alt={`Image ${index}`} />
  ));
}