import React, { useEffect } from "react";
import ReactDom from "react-dom/client";
import "./index.css";
import PrivacyPolicy from "./Components/PrivacyPolicyOther";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  createBrowserRouter,
} from "react-router-dom";
import ApprovedClaimLodgeListing from "./Components/ClaimLodgeListingComponent copy.js";
import LoginComponent from "./Components/LoginComponents";
import DashboardComponent from "./Components/DashboardComponent";
import UserComponent from "./Components/UserComponents";
import CarLoanListingComponent from "./Components/insuranceLoanListing";
import ContactUsComponent from "./Components/ContactUsComponent";
import AddBrandComponent from "./Components/AddBrandComponent";
import AddCarComponent from "./Components/AddCarComponent";
import ApprovedUserComponent from "./Components/ApprovedUserComponent";
import FinancialLoanListing from "./Components/FinancialLoanComponent";
import ClaimLodgeListing from "./Components/ClaimLodgeListingComponent";
import FinancialLoanApprovedRejectListing from "./Components/FinancialLoanApproveRejectComponent";
import InsuranceLoanApproveRejectList from "./Components/insuranceLoanApproveRejectListing.js";
import BlockUserComponent from "./Components/BlockUserComponent.js";
import ChatScreen from "./Components/ChatScreen.js";
import { Provider, } from "react-redux";
import { Store } from "./redux";
import DepositeScreen from "./Components/DepositeScreen.js";

const App = () => {
  // async function requestPermission(){
  //     const permission = await Notification.requestPermission()
  //     if(permission === 'granted')
  //     {
  //         const messaging = getMessaging();
  //         const token = await getToken(messaging, { vapidKey: 'BJGH_B0LtCfjAzGPcqG8irryX4QDHm38BD_eqgt6odmMtXjGDr6d7lbR2QcXV3vDdPETjKRoSYB4cB9CNSZEYCY' });
  //         localStorage.setItem("deviceToken",token);
  //         onMessage(messaging, (payload) => {
  //           console.log('Message received. ', payload);
  //         });
  //     }
  //     else if(permission === "denied")
  //     {
  //       alert("You denied for the notification")
  //     }
  // }
  
  // useEffect(()=>{
  //   requestPermission()
  // },[])

  return (

    <Router>
      <Routes>
        <Route path="/" element={<LoginComponent />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/approved-user" element={<ApprovedUserComponent />} />
        <Route path="/dashboard" element={<DashboardComponent />} />
        <Route path="/user" element={<UserComponent />} />
        <Route path="/contact-us" element={<ContactUsComponent />} />
        <Route path="/add-brand" element={<AddBrandComponent />} />
        <Route path="/add-car" element={<AddCarComponent />} />
        <Route path="/insurance-loan" element={<CarLoanListingComponent />} />
        <Route path="/finance-loan" element={<FinancialLoanListing />} />
        <Route path="/claim-lodge" element={<ClaimLodgeListing />} />
        <Route path="/claim-lodge-listing" element={<ApprovedClaimLodgeListing />} />
        <Route path="/insurance-loan-approve-reject" element={<InsuranceLoanApproveRejectList />} />
        <Route path="/finance-loan-approve-reject" element={<FinancialLoanApprovedRejectListing />} />
        <Route path="/block-user" element={<BlockUserComponent />} />
        <Route path="/chat_Screen" element={<ChatScreen />} />
        <Route path="/deposite_Screen" element={<DepositeScreen/>} />
        
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>


  );
}




ReactDom.createRoot(document.getElementById("root")).render(
  <Provider store={Store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
  </Provider>
);
