import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ChatList } from "react-chat-elements"
import 'react-chat-elements/dist/main.css';
import ChatHook from "../constants/chatHook";
import { useSelector, useDispatch } from 'react-redux'
import { setInitialChatUser, setToken } from "../redux/reducer";
const ChatUsersComponent = (location) => {
    const { getRecentChatUsers } = ChatHook()
    const dispatch = useDispatch()
    const { token, usersList } = useSelector((state) => state?.sliceReducer)
    const getSession = () => {
        getRecentChatUsers(token)
    }

     //console.log(usersList[0]?.message?.threadId,"usersList=======>")
    useEffect(() => {
        getSession()
        if (location?.location?.state?.param != undefined || null) {
            dispatch(setInitialChatUser(location?.location?.state?.param))
        } else {
            dispatch(setInitialChatUser(usersList[0]))
            getRecentChatUsers(token)
        }
    }, [])


    const setUserData = (item) => {
        dispatch(setInitialChatUser(item))
    }




    return (
        <div className="chat_users_list">
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', padding: 10 }}>
                <h5>Messages</h5>
                <button style={{ alignItems: 'center', alignSelf: 'center', marginLeft: 5, borderRadius: 15, borderWidth: 0, height: 30, width: 30, 
                // backgroundColor: '#FF914D' 
                backgroundColor:'#F3F0F0'
                }}>
                    <FaPlus style={{ color: '#F3F0F0' }} />
                </button>
            </div>

            {usersList.length > 0 ?

            usersList?.map((item) => {
                return (
                    <ChatList
                        onClick={() => { setUserData(item) }}
                        className='chat-list'
                        dataSource={[
                            {
                                avatar: item?.matchingDetails?.image,
                                alt: 'kursat_avatar',
                                title: item?.matchingDetails?.name,
                                subtitle:item?.matchingDetails?.type === 'text'? item?.matchingDetails?.message : "new message",
                                date: new Date(),
                                unread: 3,
                            }
                        ]} />
                )
            })
            :
            <h4 style={{alignSelf:'center'}}>No Chat Found</h4>
        }
        </div>
    );
};

export default ChatUsersComponent;
