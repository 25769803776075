import React, { useEffect, useState } from "react";
import { LOGO } from "../constants/constantMessages";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import UseSession from "../constants/useSession";
import Profile from "../Modals/Profile";
import { useSelector, useDispatch } from 'react-redux'
import { setToken } from "../redux/reducer";
import ChatHook from "../constants/chatHook";
const SidebarComponent = () => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("");
  const location = useLocation();
  const { mySession, removeSession } = UseSession()
  const { getRecentChatUsers } = ChatHook()
  const { token } = useSelector((state) => state?.sliceReducer)
  const handleMenuItemClick = (menuName) => {
    setActiveMenu(menuName);
  };

  const dispatch = useDispatch()

  const routeName = location.pathname;
  const getUsers = () => {
    getRecentChatUsers(token)
  }
  // console.log('Current route name:', routeName);
  useEffect(() => {
    getUsers()
    const getSession = () => {
      const savedData = localStorage.getItem('myData');
      if (savedData != null) {
        const data = JSON.parse(savedData)
        dispatch(setToken(data?.token))
        navigate(routeName)
      } else {
        navigate("/")
      }

    };
    getSession()

  }, [])


  const isMenuItemActive = (menuPath) => {
    return location.pathname === menuPath ? "active-menu" : "inactive-menu";
  };
  const handleLogout = () => {
    removeSession()
  };
  return (
    <div className="sideBarComponent">

      <div className="logoSide">
        <img src={LOGO} alt="LogoSide" />
      </div>
      <div className="main">
        <ul className="menu">
          {" "}

          {mySession?.loggedInUser.userType != 'banks' &&
            <Link to="/dashboard" onClick={() => handleMenuItemClick("/dashboard")} style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/dashboard")}>
                <span > Home</span>
              </li>
            </Link>
          }
          {mySession?.loggedInUser.userType === 'superAdmin' &&
            <Link
              to="/approved-user"
              onClick={() => handleMenuItemClick("/approved-user")}
              style={{ textDecoration: 'none' }}>
              {" "}
              <li className={isMenuItemActive("/approved-user")}>
                <span>Users</span>
              </li>{" "}
            </Link>
          }
          {mySession?.loggedInUser.userType === 'superAdmin' &&
            <Link to="/user" onClick={() => handleMenuItemClick("/user")} style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/user")}>
                {" "}
                <span> User Request</span>
              </li>{" "}
            </Link>
          }
          {mySession?.loggedInUser.userType === 'superAdmin' &&
            <Link
              to="/block-user"
              onClick={() => handleMenuItemClick("/block-user")} style={{ textDecoration: 'none' }}
            >
              <li className={isMenuItemActive("/block-user")}>
                <span>Blocked Users</span>
              </li>{" "}
            </Link>
          }

          {
            <Link to="/insurance-loan-approve-reject" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/insurance-loan-approve-reject")}>
                <span>Insurance Loan</span>
              </li>
            </Link>}
          {mySession?.loggedInUser.userType != "insurance" &&
            <Link to="/insurance-loan" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/insurance-loan")}>
                {" "}
                <span> Insurance Loan Request</span>
              </li>
            </Link>}

          <Link to="/finance-loan-approve-reject" style={{ textDecoration: 'none' }}>
            <li className={isMenuItemActive("/finance-loan-approve-reject")}>
              <span> Financial Loan</span>
            </li>
          </Link>
          {mySession?.loggedInUser.userType != "insurance" &&
            <Link to="/finance-loan" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/finance-loan")}>
                <span> Financial Loan Request</span>
              </li>
            </Link>}


          {mySession?.loggedInUser.userType != "insurance" &&
            <Link to="/claim-lodge" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/claim-lodge")}>
                <span>Claims Lodge Request</span>
              </li>
            </Link>}
          {mySession?.loggedInUser.userType != "insurance" &&
            <Link to="/claim-lodge-listing" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/claim-lodge-listing")}>
                <span>Claims List</span>
              </li>
            </Link>}
          {mySession?.loggedInUser.userType != 'banks' &&
            <Link to="/add-brand" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/add-brand")}>
                <span>Add Brand</span>
              </li>
            </Link>}

          {mySession?.loggedInUser.userType != 'banks' &&
            <Link to="/add-car" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/add-car")}>
                <span>Add Models</span>
              </li>
            </Link>}
          {mySession?.loggedInUser.userType === 'Admin' &&


            <Link to="/chat_Screen" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/chat_Screen")}>
                <span>Messages</span>
              </li>
            </Link>}


          <Link to="/contact-us" style={{ textDecoration: 'none' }}>
            <li className={isMenuItemActive("/contact-us")}>
              <span>Contact Us</span>
            </li>
          </Link>
          {mySession?.loggedInUser.userType === 'superAdmin'  &&
           
            <Link to="/deposite_Screen" style={{ textDecoration: 'none' }}>
              <li className={isMenuItemActive("/deposite_Screen")}>
                <span> Deposits</span>
              </li>
            </Link>}

        </ul>
        <Button
          className="logoutButton"
          variant="outline-light"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default SidebarComponent;
