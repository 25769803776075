import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loanRenderImages } from "../constants/helper";

const InsuranceDetailModal = ({ close, showDetailsModal, selectedUser }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [myUrl, setUrl] = useState("");

  const onImageClick = (url) => {
    setModal(true);
    setUrl(url);
  };

  return (
    <>
      {console.log(selectedUser)}
      {selectedUser && (
        <div className="detailmain">
          <div className="headerDetail">
            <h2>Insurance Loan Detail</h2>
            <Button
              variant="secondary"
              onClick={close}
              style={{
                backgroundColor: "#FF914D",
                border: "#FF914D",
                height: 40,
                margin: 10,
              }}
            >
              Close
            </Button>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-start",
              paddingRight: 10,
            }}
          >
            <div>
              <p>
                <strong>Bank Account Number:</strong>{" "}
                {selectedUser?.bankAccountNumber}
              </p>

              <p>
                <strong>Bank Name:</strong> {selectedUser?.bankName}
              </p>
              <p>
                <strong>Name:</strong> {selectedUser?.userId?.name}
              </p>
              <p>
                <strong>Mobile Number:</strong>{" "}
                {selectedUser?.userId?.phoneNumber}
              </p>

              <p>
                <strong>Car Condition:</strong> {selectedUser?.carCondition}
              </p>

              <p>
                <strong>Color:</strong> {selectedUser?.color}
              </p>
              <p>
                <strong>Employment Address:</strong>{" "}
                {selectedUser?.employmentAddress}
              </p>
              <p>
                <strong>Engine No.:</strong> {selectedUser?.engineNo}
              </p>

              <p>
                <strong>Form Filled By:</strong> {selectedUser?.formFilledBy}
              </p>
            </div>

            <div  style={{
              marginLeft:"10%"
            }}>
              <p>
                <strong>Fuel Type:</strong> {selectedUser?.fuelType}
              </p>
              <p>
                <strong>Insurance Company:</strong>{" "}
                {selectedUser?.insuranceCompany}
              </p>

              <p>
                <strong>License Plate No:</strong>{" "}
                {selectedUser?.licensePlateNo}
              </p>
              <p>
                <strong>Loan Term:</strong> {selectedUser?.loanTerm}
              </p>

              <p>
                <strong>Vehicle Brand:</strong> {selectedUser?.vehicleBrand}
              </p>
              <p>
                <strong>Vehicle Libre:</strong> {selectedUser?.vehicleLibre}
              </p>

              <p>
                <strong>Vehicle Model:</strong> {selectedUser?.vehicleModel}
              </p>
              <p>
                <strong>Vehicle Name:</strong> {selectedUser?.vehicleName}
              </p>
              <p>
                <strong>Vehicle Registration No:</strong>{" "}
                {selectedUser?.vehicleRegistrationNo}
              </p>
            </div>
          </div>

          <div
            style={{
              marginLeft: 190,
              display: "flex",
              flexDirection: "row",
            
            }}
          >
            <p style={{ display: "flex",flexDirection:'column' }}>
              <strong>Libre Front Image</strong>
              <br/>
              <>
              {loanRenderImages(selectedUser.libreFrontPic, onImageClick)}
              </>
            </p>
           &nbsp;
           &nbsp;
           &nbsp;
           &nbsp;
            <p style={{ display: "flex",flexDirection:'column' }}>
              <strong>Libre Back Image</strong>
              <br/>
              <>{loanRenderImages(selectedUser.libreBackPic, onImageClick)}</>
            </p>
            &nbsp;
           &nbsp;
           &nbsp;
           &nbsp;
              <p style={{ display: "flex",flexDirection:'column' }}>
              <strong>Vehicle Image</strong>
              <br/>
             <>{loanRenderImages(selectedUser.vehiclePhoto, onImageClick)}</>
            </p>
            &nbsp;
           &nbsp;
           &nbsp;
           &nbsp;
              <p style={{ display: "flex",flexDirection:'column' }}>
              <strong>Drving Licence Image</strong>
              <br/>
              <>{loanRenderImages(selectedUser.drivingLicenceImage, onImageClick)}</>
            </p>
            
          </div>
        </div>
      )}

      <Modal show={modal}>
        <Modal.Header
          closeButton
          onHide={() => {
            setModal(false);
          }}
        >
          {/* <Modal.Title>Create Account</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              height: 570,
              width: "100%",
              alignSelf: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              style={{ height: 540, width: "100%", alignSelf: "center" }}
              src={myUrl.trim()}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InsuranceDetailModal;
