import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
const UseSession = () => {

    const [mySession, setSession] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        if(mySession === null){
            getSession()
        }
      
    }, [])

    const saveSession = (value) => {
       
        localStorage.setItem('myData', JSON.stringify(value));
    };

    const removeSession = () => {
        localStorage.removeItem("myData");
        navigate("/login");
    };


    const getSession = () => {
        const savedData = localStorage.getItem('myData');
        if (savedData!= null) {
            const data = JSON.parse(savedData)
            // console.log(data,"data=======>")
            setSession(data);
            // navigate("/dashboard")
        }

        


    };

    return { mySession, saveSession, removeSession, getSession }


}

export default UseSession