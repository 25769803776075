import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { renderImages } from "../constants/helper";

const DetailModal = ({ close, showDetailsModal, selectedUser }) => {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false)
    const [myUrl, setUrl] = useState('')

    const onImageClick = (url) => {
        setModal(true)
        setUrl(url)
    }



    return (


        <>
            {selectedUser && (
                <div className="detailmain">
                    <div className="headerDetail">
                        <h2>User Detail</h2>
                        <Button
                            variant="secondary"
                            onClick={close}
                            style={{ backgroundColor: "#FF914D", border: "#FF914D", height: 40, margin: 10 }}
                        >
                            Close
                        </Button>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', paddingRight: 10 }}>
                        <img
                            className="userImages"
                            src={
                                selectedUser?.image ||
                                "https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png"
                            }
                        />

                        <div>


                            <p>
                                <strong>Name:</strong> {selectedUser?.name}
                            </p>
                            <p>
                                <strong>Email:</strong> {selectedUser?.email}
                            </p>
                            <p>
                                <strong>Is Verified:</strong> {selectedUser?.isVerified}
                            </p>
                            <p>
                                <strong>Phone Number:</strong> {selectedUser?.phoneNumber}
                            </p>

                            <p>
                                <strong>Zone:</strong> {selectedUser?.zone}
                            </p>
                            <p>
                                <strong>City:</strong> {selectedUser?.city}
                            </p>
                            <p>
                                <strong>Sub City:</strong> {selectedUser?.subCity}
                            </p>
                            <p>
                                <strong>House Number:</strong> {selectedUser?.houseNumber}
                            </p>

                            <p>
                                <strong>Land Line Number:</strong>{" "}
                                {selectedUser?.landlineNumber}
                            </p>



                        </div>

                        <div>
                            <p>
                                <strong>Father Name:</strong> {selectedUser?.fName}
                            </p>
                            <p>
                                <strong>Country Code:</strong> {selectedUser?.countryCode}
                            </p>
                            <p>
                                <strong>Gender:</strong> {selectedUser?.gender}
                            </p>
                            <p>
                                <strong>Date Of Birth:</strong> {selectedUser?.dateOfBirth}
                            </p>
                            <p>
                                <strong>Nationality:</strong> {selectedUser?.nationality}
                            </p>

                            <p>
                                <strong>Total Male Number:</strong>{" "}
                                {selectedUser?.totalMaleNumber}
                            </p>
                            <p>
                                <strong>Total Female Number:</strong>{" "}
                                {selectedUser?.totalFemaleNumber}
                            </p>
                            <p>
                                <strong>Type of Identification Card:</strong>{" "}
                                {selectedUser?.typeOfIdentificationCard}
                            </p>
                            <p>
                                <strong>Id Number:</strong> {selectedUser?.idNumber}
                            </p>


                        </div>

                        <div>
                            <p>
                                <strong>Education Status:</strong>{" "}
                                {selectedUser?.educationStatus}
                            </p>
                            <p>
                                <strong>Country:</strong> {selectedUser?.country}
                            </p>
                            <p>
                                <strong>Current Address:</strong> {selectedUser?.currentAddress}
                            </p>
                            <p>
                                <strong>Region:</strong> {selectedUser?.region}
                            </p>

                            <p>
                                <strong>Grand Mother Name:</strong> {selectedUser?.gMName}
                            </p>
                            <p>
                                <strong>Marital Status:</strong> {selectedUser?.maritalStatus}
                            </p>
                            <p>
                                <strong>Full Name of Spouse:</strong>{" "}
                                {selectedUser?.fullNameOfSpouse}
                            </p>
                            <p>
                                <strong>Total Family Number:</strong>{" "}
                                {selectedUser?.totalFamilyNumber}
                            </p>


                        </div>


                    </div>


                    <div style={{ marginLeft: 190 }}>
                        <p>

                            <strong>Facial Pictures:</strong></p>
                        {renderImages(selectedUser.facialPicture, onImageClick)}

                        <p><strong>Identification Cards:</strong></p>
                        {renderImages(selectedUser.identificationCard, onImageClick)}
                    </div>


                </div>



            )}

            <Modal show={modal}  >
                <Modal.Header closeButton onHide={() => { setModal(false) }}>
                    {/* <Modal.Title>Create Account</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: 570, width: '100%', alignSelf: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <img style={{height: 540, width: '100%', alignSelf: 'center',}}  src={myUrl.trim()}  />
                    </div>
                </Modal.Body>
            </Modal>

        </>

    )
}


export default DetailModal