import React, { useEffect, useRef, useState } from "react";
import { FaEllipsisV, FaFileUpload, FaPaperPlane } from "react-icons/fa";
import { MessageList, ChatItem, MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import ChatHook from "../constants/chatHook";
import { useSelector } from "react-redux";
import axios from "axios";
const ChatListInputComponent = (location) => {
  const { senChatMessage, getChatMeassages, senImageMessage } = ChatHook();
  const [inputValue, setInputValue] = useState("");
  const [autoFetch, setAutoFetch] = useState(true);
  const [intervalId, setIntervalId] = useState(null);

  const { chatUser, token, messages } = useSelector(
    (state) => state?.sliceReducer
  );
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const messagesEndRef = useRef(null);

  const sendMsag = async () => {
    const msg = inputValue;
    const param = {
      message: msg,
      reciverId: chatUser?.matchingDetails?._id,
      mytoken: token,
    };
    setInputValue("");
    senChatMessage(param);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  
  useEffect(() => {
    getChatMeassages(token, chatUser?.message?.threadId);
  }, [chatUser?.message?._id]);

  const handleUpload = (reader) => {
    const param = {
      image: reader,
      reciverId: chatUser?.matchingDetails?._id,
      mytoken: token,
    };
    senImageMessage(param);
  };

  const handleImageUpload = (Image) => {
    if (!Image) {
      return;
    }

    const byteCharacters = atob(Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });
    const formData = new FormData();
    formData.append("image", blob, "image.png");
    const apiUrl = "http:/16.16.47.146:3213/api/upload-image";

    formData.append("image", Image);
    axios
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        handleUpload(response.data?.filename);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const handleFileInputChange = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader?.result);
        handleImageUpload(reader?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="chat_list_input">
      <div style={{ flexDirection: "row", display: "flex" }}>
        <ChatItem
          className="profile_Setup"
          avatar={chatUser?.matchingDetails?.image}
          alt="kursat_avatar"
          title={chatUser?.matchingDetails?.name}
          subtitle="Online"
          date={""}
          unread={0}
        />
        <div
          style={{
            backgroundColor: "white",
            alignContent: "center",
            height: 64,
            marginTop: 6,
            width: 60,
            marginLeft: -8,
          }}
        >
          <button
            style={{
              alignItems: "center",
              alignSelf: "center",
              marginLeft: 5,
              borderRadius: 15,
              borderWidth: 0,
              height: 30,
              width: 30,
              backgroundColor: "#FF914D",
            }}
          >
            <FaEllipsisV style={{ color: "white" }} />
          </button>
        </div>
      </div>
      <div className="chat_container">
        {messages?.map((item) => {
          console.log("========>>>>>>Messages",messages)
          return item?.type === "image" ? (
            <MessageBox
              position={
                item?.senderId === chatUser?.matchingDetails?._id
                  ? "left"
                  : "right"
              }
              type={"photo"}
              title={
                item?.senderId === chatUser?.matchingDetails?._id
                  ? chatUser?.matchingDetails?.name
                  : ""
              }
              data={{
                uri: item?.message,
                width: 200,
                height: 200,
              }}
            />
          ) : (
            <MessageList
              className="message-list"
              lockable={true}
              toBottomHeight={"100%"}
              dataSource={[
                {
                  position:
                    item?.senderId === chatUser?.matchingDetails?._id
                      ? "left"
                      : "right",
                  type: item?.type === "image" ? "photo" : "text",
                  title:
                    item?.senderId === chatUser?.matchingDetails?._id
                      ? chatUser?.matchingDetails?.name
                      : "",
                  text: item?.message,
                },
              ]}
            />
          );
        })}
        <div ref={messagesEndRef} />
      </div>

      <div className="write_message">
        <input
          className="message_Input"
          placeholder="Type here..."
          multiline={true}
          value={inputValue}
          multiple
          onChange={(e) => setInputValue(e.target.value)}
        />

        {inputValue.length > 0 ? (
          <button
            onClick={(e) => {
              sendMsag();
            }}
            style={{
              alignItems: "center",
              alignSelf: "center",
              marginLeft: 5,
              borderRadius: 5,
              borderWidth: 0,
              height: 30,
              width: 50,
              backgroundColor: "#FF914D",
              justifyContent: "center",
            }}
          >
            <FaPaperPlane style={{ color: "white" }} />
          </button>
        ) : (
          <button
            onClick={handleButtonClick}
            style={{
              alignItems: "center",
              alignSelf: "center",
              height: 30,
              width: 50,
              backgroundColor: "#FF914D",
              justifyContent: "center",
              borderWidth: 0,
              display: "flex",
              borderRadius: 5,
            }}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              style={{ display: "none" }}
            />

            <FaFileUpload style={{ color: "white" }} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatListInputComponent;
